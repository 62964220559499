<template>
  <div class="login-container">
    <div class="login-form" v-if="showForm">
      <v-text-field
        label="Usuario"
        v-model="username"
        outlined
      ></v-text-field>
      <v-text-field
        outlined
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        name="input-10-1"
        label="Contraseña"
        @click:append="show1 = !show1"
      ></v-text-field>
      <div class="button-container">
        <v-btn
          text
          :disabled="!username"
          class="text-caption font-weight-medium indigo--text text--lighten-1"
          @click="handleRecoverPassword"
        >
          Olvide mi contraseña
        </v-btn>
      </div>
      <div class="button-container">
        <v-btn
          @click="handleLogInClick"
          color="primary"
        >
          Ingresar
        </v-btn>
      </div>
    </div>
    <div v-else>
      <v-card
        class="mx-auto"
        color="#FFFFFF"
        max-width="700"
      >
        <v-card-title>
          <v-icon
            large
            left
          >
            mdi-email
          </v-icon>
          <span class="text-h5 font-weight-medium">
            <span class="google-blue">G</span><span class="google-red">o</span><span class="google-yellow">o</span><span class="google-blue">g</span><span class="google-green">l</span><span class="google-red">e</span>
          </span>
        </v-card-title>

        <v-card-text class="font-weight-light">
          Se ha enviado un mail de recuperacion de contraseña a {{ username }}. Hacer click en el enlace para recuperar la contraseña del email.
        </v-card-text>

        <v-card-actions>
          <v-list-item class="grow">
            <v-row
              align="center"
              justify="end"
            >
              <v-btn
                color="#DB4437"
                @click="handleGoToGmail"
              >
                <span class="font-weight-bold white--text">Ir a Gmail</span>
              </v-btn>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      flat
      width="300"
    >
      <v-card
        class="py-2"
        flat
        :color="status === 'pending' ? 'primary' : 'error'"
        dark
      >
        <v-card-text>
          <span v-if="status === 'pending'">
            Verificando usuario y contraseña
          </span>
          <span class="text-title font-weight-bold" v-else>
            Usuario o Contraseña incorrectos
          </span>
          <v-progress-linear
            v-if="status === 'pending'"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from '@/firebaseSDK'

export default {
  data: () => ({
    show1: false,
    username: '',
    password: '',
    status: 'neutral',
  }),

  computed: {
    dialog () {
      return this.status === 'pending' || this.status === 'wrongCredentials'
    },

    showForm () {
      return this.status === 'pending' || this.status === 'wrongCredentials' || this.status === 'neutral'
    }
  },

  methods: {
    async handleLogInClick () {
      this.status = 'pending'
      try 
      {
        let userCredential = await auth.signInWithEmailAndPassword(this.username, this.password)
        localStorage.setItem('user', JSON.stringify(userCredential.user));
        this.$router.push('/admin')
      } catch (err) {
        this.status = 'wrongCredentials'
        setTimeout(() => {
          this.status = 'neutral'
        }, 1300)
      }
    },

    async handleRecoverPassword () {
      await auth.sendPasswordResetEmail(this.username)
      this.status = 'recover'
    },

    handleGoToGmail () {
      window.location.href = 'https://mail.google.com/mail/u/0/#inbox';
    }
  },

  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('user')) {
      next({ name: 'Admin' })
    } else {
      next()
    }
  },
}
</script>

<style scoped>
  .login-container{
    display: flex;
    justify-content: center;
    padding: 40px;
  }

  .login-form {
    width: 500px;
  }

  .button-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .google-blue {
    color: #4285F4;
  }

  .google-red {
    color: #DB4437;
  }

  .google-yellow {
    color: #F4B400;
  }
  
  .google-green {
    color: #0F9D58;
  }
</style>